@font-face {
  font-family: "NotoSansThai";
  src: url("./assets/fonts/NotoSansThai.ttf") format("truetype");
}

body {
  max-width: 480px;
  margin: 0 auto;
  height: 100vh;
  font-family: "NotoSansThai", noto-sans-Thai;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-overflow-scrolling: touch; /* Smooth scrolling for iOS */
}
