.app-auth {
  background-color: #59429b;
  min-height: 100vh;
}
.login-icon-top {
  position: absolute;
  top: 0;
  left: 0;
}
.login-icon-bottom {
  position: absolute;
  bottom: 0;
  right: 0;
}
.div-center {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 90vh;
}
.div-err-column {
  display: flex;
  flex-direction: column;
}
.text-err-login {
  font-size: 22px;
  font-weight: 400;
  line-height: 33.24px;
  text-align: center;
  color: white;
  margin-top: 10px;
}
.text-login {
  font-size: 14px;
  font-weight: 500;
  line-height: 21.15px;
  text-align: center;
  color: white;
}
.div-button {
  display: flex;
  justify-content: center;
}
.button {
  width: 90%;
  height: 48px;
  border-radius: 32px;
  background-color: white;
  z-index: 10;
}
.button-no-z {
  width: 95%;
  height: 48px;
  border-radius: 32px;
  background-color: white;
}
.div-button-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.text-button {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  margin-left: 20px;
}
.text-myprofile {
  font-size: 22px;
  font-weight: 700;
  line-height: 26.63px;
  color: white;
  text-align: center;
}

.file-upload {
  position: relative;
  cursor: pointer;
  margin-top: 20px;
}
.file-upload input {
  display: block;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0;
  cursor: pointer;
}
.image-profile {
  height: 168px;
  width: 168px;
  border-radius: 99px;
}
.icon-profile {
  position: absolute;
  bottom: 0;
  right: 0;
}
.div-textfield {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
}
.div-text-input {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}
.text-input {
  margin-top: 10px;
  width: 92%;
  height: 50px;
  border-radius: 32px;
  background-color: rgba(255, 255, 255, 0.1);
  padding-left: 20px;
  font-size: 18px;
  font-weight: 500;
}
.text-input:focus {
  outline: none;
}
.div-header {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 20px;
}
.image-logo {
  width: 32px;
  height: 32px;
  border-radius: 99px;
}
.div-tabs {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.btn-tab {
  width: 100%;
  background-color: transparent;
  background-repeat: no-repeat;
  cursor: pointer;
  overflow: hidden;
  outline: none;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.btn-text-tab {
  margin-top: 8px;
  font-size: 14px;
  font-weight: 500;
  line-height: 21.5px;
}
.btn-card {
  width: 100%;
  background-color: transparent;
  background-repeat: no-repeat;
  cursor: pointer;
  overflow: hidden;
  outline: none;
  border: none;
}
.div-card {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  padding: 5px 20px 5px 20px;
}
.image-card {
  width: 100%;
  height: 200px;
  border-radius: 9px;
}
.div-card-description {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.text-card-summary {
  font-size: 18px;
  font-weight: 500;
  line-height: 28.57px;
  text-align: left;
  margin-top: 10px;
}
.text-card-title {
  font-size: 18px;
  font-weight: 500;
  line-height: 28.57px;
  text-align: left;
}
.divider {
  display: inline-block;
  width: 2px;
  margin: 0 10px;
  height: 1em;
}

.image-cover {
  width: 100%;
  height: 189px;
}
.div-room-padding-column {
  padding: 10px;
  display: flex;
  flex-direction: column;
}
.text-room {
  font-size: 18px;
  font-weight: 600;
  line-height: 27px;
}
.text-room-amenities {
  margin-top: 15px;
  font-size: 12px;
  font-weight: 500;
  line-height: 15.6px;
}
.div-divider-room {
  border: 1px solid #d2d2d2;
}
.div-day {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: scroll;
}
::-webkit-scrollbar {
  display: none;
}
.btn-day {
  background-color: transparent;
  border: none;
  background: none;
  display: flex;
  flex-direction: column;
  padding: 20px;
  height: 54px;
  border-radius: 16px;
  margin-right: 12px;
  align-items: center;
  justify-content: center;
}

.btn-text-month {
  font-size: 10px;
  font-weight: 500;
  line-height: 15px;
}
.btn-text-day {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}
.modal-text-review {
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
}
.modal-text-date-time {
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  color: #6719cf;
}
.image-cover-modal {
  margin-top: 15px;
  margin-bottom: 15px;
  width: 100%;
  height: 182.1px;
  border-radius: 18px;
}

.modal {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: flex-end;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease;
}

/* When modal is shown */
.modal.show {
  opacity: 1;
  visibility: visible;
}

/* Modal content box */
.modal-content {
  background-color: white;
  width: 100%;
  max-width: 500px;
  padding: 20px;
  border-radius: 10px 10px 0 0;
  position: relative;
  transform: translateY(100%);
  transition: transform 0.3s ease;
}

/* Slide the modal up when shown */
.modal.show .modal-content {
  transform: translateY(0);
}

/* Close button */
.close-btn {
  /* position: absolute;
  top: 10px;
  right: 20px;
  font-size: 30px;
  cursor: pointer; */
  display: flex;

  justify-content: center;
}
.main-container {
  overflow-y: auto; /* Enables vertical scroll */
  height: 100vh; /* Ensures full viewport height */
}
.content-padding {
  padding-bottom: 100px; /* Adjust based on footer height */
}
.modal-loading {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}
